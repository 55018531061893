import React, { useContext, useEffect } from "react";
import { ForecastContext } from "../../templates/context/ForecastContext";
import { createUserEvent } from "../../services/UserEventsService";

import {
  getProductPrediction,
  getMonthAbbreviation,
  getForecastPredictions,
  isFutureMonth,
  defineMonthColumns,
} from "../../utils/utils";

import ProductHierarchyHeading from "../ProductHierarchyHeading/ProductHierarchyHeading";
import AreaRangeChart from "../AreaRangeChart/AreaRangeChart";

export default function ProductAccuracyChart({ productId, unit, currentMonth, productPredictions }) {
  const { historicForecasts, selectedForecast } = useContext(ForecastContext);

  const colors = [
    "#f58b4d",
    "#BFBFBF",
    "#12a9b3", //azul claro
    "#aeaa10", //amarelo escuro
    "#9544d2", //roxo
    "#e52590", //rosa
    "#178751", //verde escuro
    "#eb3c3c", //vermelho
    "#5d62f6", //azul escuro
    "#b2f65d", //verde limão
    "#f8fa4f", //amarelo claro
  ];

  const futurePredictions = productPredictions.filter(
    prediction => prediction.forecast.forecastId === selectedForecast.forecastId
  );
  const futureColumns = defineMonthColumns(futurePredictions);
  const allHistoricMonths = defineHistoricMonths();
  const allMonths = allHistoricMonths.concat(futureColumns);

  const twelveNumbersArray = Array.from({ length: 12 }, (_, i) => i + 1);

  const uniqueYears = [...new Set(allMonths.map(month => month.year))];
  const completeMonths = uniqueYears.map(year => twelveNumbersArray.map(number => ({ month: number, year: year }))).flat();

  const currentMonthIndex = completeMonths.findIndex(
    month => month.month === selectedForecast.month && month.year === selectedForecast.year
  );
  const pastMonths = completeMonths.slice(0, currentMonthIndex);

  const initialNullMonthsQuantity = isFirstMonthJanuary(futureColumns) ? 0 : selectedForecast.month - 2;
  const indexOfCurrentMonth = 12 - initialNullMonthsQuantity - 1;
  const monthsUntillEndOfYear = isFirstMonthJanuary(futureColumns) ? futureColumns : futureColumns.slice(0, indexOfCurrentMonth);

  const twelveMonthsArray = twelveNumbersArray.map(number => ({ month: number, year: 2020 }));
  const xLabels = twelveMonthsArray.map(month => getMonthAbbreviation(month.month));

  function defineAllRanges(productId) {
    const pastRanges = definePastRanges(productId);
    const values = monthsUntillEndOfYear.map(month => {
      const monthPrediction = futurePredictions.find(
        prediction => prediction.month === month.month && prediction.year === month.year && prediction.productId === productId
      );
      return [Number(monthPrediction?.confidenceIntervalMin), Number(monthPrediction?.confidenceIntervalMax)];
    });
    return pastRanges.concat(values);
  }

  function definePastRanges(productId) {
    const pastMonthsToPutPastRanges = pastMonths.filter(month => month.year === selectedForecast.year);

    const pastPredictions = pastMonthsToPutPastRanges.map(month => {
      const minCI = getProductPredictionFromOfficialPastForecast(month, productId, productPredictions)?.confidenceIntervalMin;
      const maxCI = getProductPredictionFromOfficialPastForecast(month, productId, productPredictions)?.confidenceIntervalMax;
      return [!isNaN(minCI) ? Number(minCI) : null, !isNaN(maxCI) ? Number(maxCI) : null];
    });
    return pastPredictions;
  }

  function defineFutureMonthsData(productId) {
    const futureColumnLabels = [
      {
        label: "Forecast IA",
        name: definePredictionToUse,
        color: colors[0],
        lineStyle: "shortDash",
      },
      {
        label: "Plano Atual",
        name: definePredictionToUse,
        color: colors[1],
        lineStyle: "shortDash",
      },
    ];

    return futureColumnLabels.map(columnLabel => {
      const newZeroHistoricMonths = Array.from({ length: initialNullMonthsQuantity }, () => null);
      const lastHistoricMonth = allHistoricMonths[allHistoricMonths.length - 1];
      const newMonthColumns = !isFirstMonthJanuary(futureColumns)
        ? [lastHistoricMonth].concat(monthsUntillEndOfYear)
        : monthsUntillEndOfYear;

      let values = newMonthColumns.map((month, index) => {
        let prediction;
        let columnLabelName = columnLabel.name(columnLabel.label, month, currentMonth);

        if (isFirstMonthNotJanuary(index, month)) {
          columnLabelName = "realized";
          prediction = getProductPredictionFromOfficialPastForecast(month, productId);
        } else {
          prediction = futurePredictions.find(
            prediction => prediction.month === month.month && prediction.year === month.year && prediction.productId === productId
          );
        }

        const value = prediction?.[columnLabelName];
        return value ? value : null;
      });

      return {
        ...columnLabel,
        values: newZeroHistoricMonths.concat(values),
        marker: false,
      };
    });
  }

  function isFirstMonthJanuary(futureMonths) {
    return futureMonths[0].month === 1;
  }

  function isFirstMonthNotJanuary(index, month) {
    return index === 0 && month.month !== 1;
  }

  function definePredictionToUse(columnLabel, month, currentMonth) {
    const isCurrentMonth = month.month === currentMonth.month && month.year === currentMonth.year;
    switch (columnLabel) {
      case "Forecast IA":
        return isCurrentMonth ? "officialAidronPrediction" : "aidronPrediction";
      case "Plano Atual":
        return isCurrentMonth ? "officialClientPrediction" : "clientPrediction";
      default:
        return "invalid";
    }
  }

  function createLines(productId) {
    const pastMonthsWithRealizedValue = defineLines(productId);
    const preResult = cutInto12MonthsArrays([...pastMonthsWithRealizedValue]);

    const realizedSeries = preResult.map((arr, index) => {
      const values = [];
      arr.forEach(el => {
        if (!isFutureMonth(currentMonth, el)) {
          values.push(el.realized);
        }
      });

      return {
        label: `Realizado ${arr[0].year}`,
        marker: false,
        values: values,
        visible: index === preResult.length - 1,
      };
    });

    const orderedRealizedSeries = realizedSeries.reverse();
    return defineFutureMonthsData(productId).concat(orderedRealizedSeries);
  }

  function defineLines(productId) {
    const realizedSeries = pastMonths.map(month => {
      const productPrediction = getProductPrediction(month, productId, productPredictions);
      const prediction = !isNaN(productPrediction?.realized) ? productPrediction.realized : null;
      return { ...month, realized: prediction };
    });
    return realizedSeries;
  }

  function defineHistoricMonths() {
    const years = [...new Set(historicForecasts.map(forecast => forecast.year))];
    const twelveNumbers = Array.from({ length: 12 }, (_, i) => i + 1);
    const monthsAndYears = years
      .map(year => {
        return twelveNumbers.map(num => {
          return { year: year, month: num };
        });
      })
      .flat();

    const currentMonthIndex = monthsAndYears.findIndex(
      month => month.month === selectedForecast.month && month.year === selectedForecast.year
    );

    const pastMonths = monthsAndYears.slice(0, currentMonthIndex);
    return pastMonths;
  }

  function cutInto12MonthsArrays(arr) {
    const result = [];
    let i = 0;
    while (i < arr.length) {
      result.push(arr.slice(i, i + 12));
      i += 12;
    }
    return result;
  }

  function getProductPredictionFromOfficialPastForecast(month, productId) {
    const officialPastForecast = historicForecasts.find(
      forecast => forecast.month === month.month && forecast.year === month.year
    );

    if (officialPastForecast) {
      const forecastPredictions = getForecastPredictions(officialPastForecast.forecastId, productPredictions);
      return getProductPrediction(month, productId, forecastPredictions);
    } else {
      return {};
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    createUserEvent(token, { event: "Gráfico Assertividade Produto", page: "Análise Assertividade", productId: productId });
  }, []);

  return (
    <>
      <ProductHierarchyHeading productId={productId} />
      <AreaRangeChart
        xLabels={xLabels}
        ranges={defineAllRanges(productId)}
        lines={createLines(productId)}
        colors={colors}
        unit={unit}
        lastHistoricMonthLabel={xLabels[initialNullMonthsQuantity]}
        style={{ width: "80%" }}
      />
    </>
  );
}
